<template>
  <main class="main">
    <nav class="breadcrumb-nav border-0 mb-0">
      <div class="container">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/">Home</router-link>
          </li>
          <li class="breadcrumb-item active">Contact</li>
        </ol>
        <h4>{{ $t("contact") }}</h4>
      </div>
    </nav>

    <div class="page-content pb">
      <div class="container">
        <div class="row flex-column-reverse flex-md-row">
          <div class="col-lg-6 mb-2 mb-lg-0">
            <div class="contact-content-box">
              <div
                v-html="contactInformation.content"
                v-if="contactInformation"
              />
              <!-- static -->
              <!-- <h6>Contactgegevens Mobile Supplies</h6>

                            <h6 class="mb-0 mt-2">Post - en retouradres:</h6>
                            <p>Postbus 10376</p>
                            <p>600 GL Weert</p>
                            <p>Nederland</p>

                            <h6 class="mb-0 mt-2">Vestigingsadres:</h6>
                            <p><u>Mobile Supplies</u></p>
                            <p>Postbus 10376</p>
                            <p>600 GL Weert</p>
                            <p>Nederland</p>
                            <a href="tel:+31(0)495752039">+31 (0) 495 752039</a> -->
              <!-- static -->

              <div class="contact-info-box mt-1">
                <p class="mb-1">
                  <font-awesome-icon icon="fa-solid fa-phone" />
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Est,
                  animi!
                </p>
                <h6>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Voluptas atque qui aut impedit possimus, blanditiis voluptatem
                  officiis ut quo? Iure voluptatem vitae fugiat incidunt
                  consequatur dolore labore repellat asperiores, dolorem
                  excepturi, soluta ad eos sed deleniti quia molestias! Ipsum
                  magnam vitae minus quis ducimus. Delectus id est fuga
                  inventore deleniti.
                </h6>
              </div>

              <router-link to="/klantenservice/" class="mb-2 d-block d-md-none">
                <button class="btn btn-primary mt-2">
                  {{ $t("back_to_mobile_supplies_customer_service") }}
                </button>
              </router-link>

              <table class="table">
                <tbody>
                  <tr>
                    <td class="p-0 py-2">Lorem ipsum dolor sit amet.</td>
                    <td class="p-0 py-2">Lorem ipsum dolor sit amet.</td>
                  </tr>
                  <tr>
                    <td class="p-0 py-2">Lorem ipsum dolor sit amet.</td>
                    <td class="p-0 py-2">Lorem ipsum dolor sit amet.</td>
                  </tr>
                  <tr>
                    <td class="p-0 py-2">Lorem ipsum dolor sit amet.</td>
                    <td class="p-0 py-2">Lorem ipsum dolor sit amet.</td>
                  </tr>
                  <tr>
                    <td class="p-0 py-2">Lorem ipsum dolor sit amet.</td>
                    <td class="p-0 py-2">Lorem ipsum dolor sit amet.</td>
                  </tr>
                </tbody>
              </table>
              <div id="map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d52949733.91626272!2d-161.79055418360187!3d35.90302921368169!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2s!4v1711657503831!5m2!1sen!2s"
                  width="100%"
                  height="300"
                  style="border: 0"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
                <!-- <gmap-map
                    :center="{lat:10, lng:10}"
                    :zoom="7"
                    map-type-id="terrain"
                    style="width: 500px; height: 300px"
                ></gmap-map> -->
              </div>
              <!-- End #map -->
              <router-link to="/klantenservice/" class="d-none d-md-block">
                <button class="btn btn-primary mt-2">
                  {{ $t("back_to_mobile_supplies_customer_service") }}
                </button>
              </router-link>
            </div>
          </div>

          <div class="col-lg-6 mb-2 mb-md-0">
            <div class="contact-content-box">
              <h6 class="mb-1">Stel je vraag via het</h6>
              <p class="mb-2">
                Het contactformulier kun je rechts onderaan op iedere pagina van
                onze website terugvinden. Vraag over een bestelling? Vul dan
                a.u.b. altijd het bestelnummer in.
              </p>

              <h6 class="mb-1">
                Stel je vraag via de chatbot (direct antwoord op de meest
                gestelde vragen)
              </h6>
              <p class="mb-2">
                Ook onze chatbot kun je rechts onderaan op iedere pagina van
                onze website terugvinden. We willen graag benadrukken dat je via
                de chatbot niet met een echt persoon praat. De chatbot is
                geprogrammeerd om op veel gestelde vragen een zo goed mogelijk
                antwoord te kunnen geven. Superhandig want hij is altijd online
                (24/7) en je krijgt direct een antwoord op je vraag.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import config from "../config";

// import Vue from 'vue'
// import * as VueGoogleMaps from 'vue2-google-maps'
// Vue.use(VueGoogleMaps, {
//   load: {
//     key: 'AIzaSyBzlLYISGjL_ovJwAehh6ydhB56fCCpPQw',
//     libraries: 'places', // This is required if you use the Autocomplete plugin
//     // OR: libraries: 'places,drawing'
//     // OR: libraries: 'places,drawing,visualization'
//     // (as you require)
//   }
// })
export default {
  computed: {
    contactInformation() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "contactinformatie"
      );
    },
  },
  metaInfo() {
    return {
      title: this.$t("contact_us"),
      link: [
        {
          rel: "canonical",
          href:
            config.app_url +
            (/\/$/.test(this.$route.fullPath)
              ? this.$route.fullPath
              : this.$route.fullPath.replace(/^\//, "") + "/"),
        },
      ],
    };
  },
};
</script>
